<template>
    <div class="hello">
        <img class="imgDiv" src="../../assets/images/li_logo.png">
        <div class="title">
            <div >欢迎登录，</div>
            <span>海米互联网公寓</span>
        </div>
        <van-form @submit="onSubmit" class="SignFlow-account">
            <div class="telDiv" >
                <div class="van-cell van-field telSort" style="background-color: transparent">
                    <div class="van-cell__title van-field__label telPrefix">
                        <span>+86</span>
                    </div>
                    <div class="van-cell__title van-field__label telInterval" >
                        <span class="telIntervalSpan">|</span>
                    </div>
                    <div class="van-cell__value van-field__value">
                        <div class="van-field__body">
                            <input type="tel" placeholder="请输入手机号" class="van-field__control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="verifyDiv">
                <div class="van-cell van-cell--center van-field verifyDivSort" style="background-color: transparent;padding-bottom: 5px;">
                    <div class="van-cell__value van-cell__value--alone van-field__value">
                        <div class="van-field__body">
                            <input type="password" inputmode="numeric" placeholder="请输入密码" class="van-field__control">
                        </div>
                    </div>
                </div>
            </div>
            <div class="info">
                <a @click="numLogin" class="logintip">验证码登录</a><a class="pwdtip">忘记密码</a>
            </div>
            <div>
                <van-button class="login"  native-type="submit">
                    登录
                </van-button>
            </div>
            <div>
                <van-button class="res" native-type="submit">
                    注册
                </van-button>
            </div>
        </van-form>
        <div class="tip">
            温馨提示：未注册的用户，登录时将自动注册，且代表您已同意<a href="#">《用户服务协议》</a><a href="#">《隐私协议》</a>
        </div>
    </div>
</template>

<script>
    import { Button,Form ,Field} from 'vant';
    export default {
        name: 'phoneLogin',
        props: {
            // eslint-disable-next-line no-undef
            msg: String
        },
        data() {
            return {
                username: '',
                password: '',
            };
        },
        components: {
            [Button.name]: Button,
            [Form.name]: Form,
            [Field.name]: Field
        },
        methods: {
            numLogin() {
              this.$router.push({
                  name: 'codeLogin',
                  query: {

                  }
              })
            },
        }


    }
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .hello{
        height:100%;
        width:100%;
    }
    .imgDiv{
        padding-left: 33px;
        padding-top: 69px;
        width: 44px;
        height: 36px;
    }
    .title{
        padding-left: 33px;
        padding-top: 12px;
        width: 182px;
        height: 74px;
        color: rgba(34, 34, 34, 1);
        font-size: 26px;
        text-align: left;
        font-weight: bold;
        font-family: PingFangSC-Semibold;
    }
    .telDiv{
        display: block;
        margin:25px 10px 0px 38px;
        border-bottom:1px solid #EFEFEF;
        width: 300px;

    }
    .telSort{
        height: 39px;

        font-size: 15px;
    }
    .telPrefix{
        width: 35px;
        color: #FF5D3B;
    }
    .telInterval{
        width: 15px;

    }
    .telIntervalSpan{
        position: relative;
        bottom: 1.2px;
        z-index: 9;
        font-size: 17px;
        color:#DDDDDD;
    }
    .verifyDiv{
        margin:25px 10px 0px 38px;
        border-bottom:  1px solid rgba(239, 239, 239, 1);
        width: 300px;
    }
    .verifyDivSort{
        height: 39px;
        font-size: 15px;
    }
    .logintip{
        padding-left: 38px;
        width: 65px;
        height: 18px;
        color: rgba(255, 93, 59, 1);
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .pwdtip{
        padding-left: 183px;
        top: 364px;
        width: 52px;
        height: 18px;
        color: rgba(49, 145, 255, 1);
        font-size: 13px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .info{
        padding-top: 10px;
    }
    .login{
        margin: 63px 15px 0px 15px;
        width: 345px;
        height: 45px;
        font-size: 16px;
        background:linear-gradient(to right,#ffc274,#ff5D3B) ;
        color: white;
        font-weight: bold;
        border-radius: 8px;
    }
    .res{
        margin: 15px 15px 0px 15px;
        width: 345px;
        height: 45px;
        font-size: 16px;
        background-color: white;
        color: #FF5D3B;
        border:1px solid #FFC274;
        font-weight: bold;
        border-radius: 8px;
    }
    .tip{
        padding: 45px 0px 0px 28px;
        font-size: 12px;
        color: #999999;
        width: 320px;
        height: 34px;
        text-indent: 8px;
        text-align: left;
    }
    .tip a{
        text-decoration: none;
        color: #3191FF;
    }
    .van-field__label {
        margin-right: 0px;
    }




</style>
